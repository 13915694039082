// UI框架
import React, { Component } from 'react';
// UI组件
import { Tooltip, message, Modal } from 'antd';
import { Icon, PtzControl } from 'libs';
// 业务组件
import plugin from 'plugin';
import { bindLifecycle } from 'utils/bindLifecycle';
import { LangConsumer } from 'utils/LangProvider';
import { clone, isZeroArray } from '../../../../common';
// import MaxMinCmp from '../../AiToolsBar/MaxMinCmp';
import _ from 'lodash';
import WebApp from '../../../../webapp';
// 样式
import './index.less';

const _name_ = 'ForeignDetectRuleConfigPlugin';

class ForeignDetectRuleConfigPluginComponent extends React.Component {
    /** 用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = _name_;
        this.videoRef = React.createRef();
        // this.DetectExcludeCmp = React.createRef();
        // this.MaxMinCmp = React.createRef();
        // this.MinDetectCmp = React.createRef();
        this.state = {
            containerID: 0, //图形容器ID
            selectShapeId: 0, // 当前选中的智能规则线Id
            selectShapeType: '', // 当前选中的规则类型
            hidePluginFlag: false, //控件是否隐藏
            pixel:[],//像素计数器
            btnStatus: [
                true, // 规则区域
            ], // 各个按钮的状态,true为点击，false为不点击
        };
    }

    componentDidMount () {
        // this.renderPlugin();
    }

    componentDidActivate () {
        let { curChannel } = this.props;
        this.cover();
        this.open(1, curChannel, 0);
    }

    componentWillUnmount () {
        this.leave();
    }

    componentWillUnactivate () {
        this.leave();
    }
    /**
     * 渲染组件
     * @param {Number} isInit 是否是初始化
     */
    renderPlugin = (isInit = true) => {
        let { curChannel } = this.props;
        this.setBtnStatus();
        // 增加插件事件监听
        isInit && this.addPluginEvent();
        setTimeout(() => {
            isInit && this.cover();
            isInit && this.open(1, curChannel, 0);
            !isInit && this.drawArea(); // 初始化不绘图
        }, 500);
    }
    /**
     * 打开视频
     * @param {Number} stream 码流
     * @param {Number} channel 通道
     * @param {Number} viewIndex 窗口号
     */
    open = (stream = 1, channel = 0, viewIndex = 0) => {
        plugin.open(stream, channel, viewIndex);
    };
    /**
     * 控件覆盖
     */
    cover = () => {
        plugin.cover(this.videoRef.current);
    };
    /**
     * 视频关闭
     */
    close = () => {
        plugin.close();
    };
    /**
     * 视频隐藏
     */
    hide = () => {
        plugin.hide();
    };

    /**
     * 离开页面触发
     * 1. 清空规则；2.销毁容器；3.取消监听；4.隐藏插件；5.停止拉流
     */
    leave = () => {
        let { containerID } = this.state;
        // 清空规则，防止在预览或者其他页面中重新绘制
        plugin.DeleteAllVideoAnalyseShape(containerID);
        // 销毁容器
        plugin.ReleaseVideoAnalyseContainer(containerID);
        plugin.addEvent('VideoAnalyzeConfig', null); //停止事件监听
        plugin.hide();
        plugin.close();
        this.setState({ containerID: 0 });
    };
    /**
     * 监听事件
     */
    addPluginEvent = () => {
        plugin.addEvent('pluginInstall', () => {
            setTimeout(() => {
                this.drawArea();
            }, 50);
        }); //登入后控件安装完成
        plugin.addEvent('VideoAnalyzeConfig', (shapeID, data, curState, preState, eventType, eventName, markedName, shapeType, eventParam) => {
            let {$t, rule, change, changeDetectRegion} = this.props;
            let {containerID} = this.state;
            console.log('*****VideoAnalyzeConfig*****');
            console.log(shapeID, data, curState, preState, eventType, eventName);
            if (curState === 3) { //绘制完成
                switch (eventName) {
                case 'drawDetectArea':
                    rule.Config.DetectRegion = data;
                    changeDetectRegion(data);
                    this.setSelectShape(shapeID, 'Polygon');
                    plugin.SetVideoAnalyseContainerTip(containerID, $t('com.DrawingIsCompletedTip'));
                    break;
                }
                // change({curRule: rule});
            }
        });
    };
    // 删除
    deleteSelectedRule = () => {
        let {$t, rule, change, changeDetectRegion} = this.props;
        let {selectShapeId, containerID, selectShapeType} = this.state;
        if (selectShapeId !== 0) { // 当有选中时才删除
            plugin.DeleteVideoAnalyseShape(containerID, selectShapeId).then(() => {
                if (selectShapeType === 'Polygon') {
                    rule.Config.DetectRegion = [];
                    changeDetectRegion([]);
                    this.drawArea(); // 绘制区域
                } else {
                    console.log('*****deleteSelectedRule*****');
                    console.log('选择的是其他类型形状!!!');
                }
                this.setState({selectShapeId: 0});
                // change({curRule: rule});
            });
        } else {
            message.error($t('com.PleaseDrawShapeTip'));
        }
    }
    // 清除所有图形
    clearRule = () => {
        let { containerID } = this.state;
        plugin.DeleteAllVideoAnalyseShape(containerID);
    };

    /**
     * 当控件隐藏时，显示视频区默认图片
     * @param [Boolean][bool]  控件是否隐藏
     */
    showVideoDefaultImage = (bool) => {
        this.setState({hidePluginFlag : bool});
    }
    //保存时图形颜色变为蓝色
    saveShapeStatus = () => {
        let {containerID, selectShapeId} = this.state;
        plugin.SetVideoAnalyseContainerTip(containerID, '');
        if (selectShapeId) {
            plugin.SelectVideoAnalyseShape(containerID, selectShapeId, false);
        }
    }
    // 绘制区域
    drawArea = async (curRule) => {
        let {$t, rule} = this.props;
        let {containerID} = this.state;
        rule = curRule ? curRule : rule;
        // 如果没有图形窗口，则需要创建一个容器
        if (containerID === 0) {
            if (plugin.isInStalled) {
                await plugin.CreateVideoAnalyseContainer().then(ID => {
                    plugin.EnableVideoAnalyseContainer(ID, true);
                    containerID = ID;
                    this.setState({ containerID });
                });
            }
        }
        // 画规则线之前，先删除原有规则线，防止重复
        plugin.DeleteAllVideoAnalyseShape(containerID).then(() => {
            // 规则存在，则绘制图形
            if (rule && rule.Enable) {
                if (rule.Config.DetectRegion.length > 0 && !isZeroArray(rule.Config.DetectRegion)) {
                    // 重新绘制规则
                    plugin.CreateMainVideoAnalyseShape(containerID, 'drawDetectArea', 'Polygon', rule.Name, JSON.stringify({ Polygon: rule.Config.DetectRegion }), '').then(shapeId => {
                        plugin.EnableVideoAnalyseShape(containerID, shapeId, true); //使能选中的规则线
                        this.setSelectShape(shapeId, 'Polygon'); // 选中检测区
                    });
                } else {
                    // 绘制规则
                    plugin.CreateMainVideoAnalyseShape(containerID, 'drawDetectArea', 'Polygon', rule.Name, '', '').then(() => {
                        plugin.SetVideoAnalyseContainerTip(containerID, $t('com.PleaseDrawShapeTip'));
                    });
                }
            }
        });
        this.setBtnStatus(0); // 设置样式
        //this.setDrawingExcludeStatus(false);
    }
    /**
     * 设置选中规则
     */
    setSelectShape = (id, type) => {
        let {selectShapeId, selectShapeType} = this.state;
        selectShapeId = id;
        selectShapeType = type;
        this.setState({selectShapeId, selectShapeType});
    }
    /**
     * 设置按钮状态
     */
    setBtnStatus = (index = -1) => {
        let {btnStatus} = this.state;
        btnStatus.forEach((k, v) => {
            btnStatus[v] = false;
        });
        if (index >= 0) btnStatus[index] = true;
        else this.setSelectShape(0, '');
        this.setState({btnStatus});
    }
    // 显示云台
    showPTZControl = () => {
        this.setState({visible: true});
    }
    // 隐藏云台
    hidePTZControl = () => {
        this.setState({visible: false});
    }

    render () {
        let {$t, rule, curChannel, curPreset, curPtzName, isShowZoom, isShowFocus, isShowIris} = this.props;
        let {hidePluginFlag, containerID, selectShapeId, btnStatus} = this.state;
        return (
            <div className={`_${_name_}_`}>

                <div className="video-wrap" ref={this.videoRef}>
                    {
                        hidePluginFlag && <div style = {{textAlign : 'center', marginTop : '30%'}}><Icon type = 'videoDefault'/></div>
                    }
                </div>
                <div className="aitoolsbar-warp">
                    <div className='icon' onClick={() => {
                        // 没有安装插件
                        if (!plugin.isInStalled) {
                            return;
                        }
                        if (!rule.Enable) {
                            // 没有使能规则提示
                            message.error($t('com.RuleIsntEnableTip'));
                        }
                        this.drawArea();
                    }} style={{color: btnStatus[0] ? '#1890FF' : ''}}>
                        <Tooltip title={$t('com.Rule')} placement='right'>
                            <Icon type="irregularity"/>
                        </Tooltip>
                    </div>
                    <div className='icon' onClick={() => {
                        // 没有安装插件
                        if (!plugin.isInStalled) {
                            return;
                        }
                        if (!rule.Enable) {
                            // 没有使能规则提示
                            message.error($t('com.RuleIsntEnableTip'));
                        } else {
                            this.deleteSelectedRule();
                        }
                    }}>
                        <Tooltip title={$t('com.Delete')} placement='right'>
                            <Icon type="delete"/>
                        </Tooltip>
                    </div>
                    {WebApp.CHANNEL_TYPE[curChannel].indexOf('SD') !== -1 ?
                        <div className='icon' onClick={this.showPTZControl}>
                            <Tooltip placement='right' title={$t('com.PtzControl')}>
                                <Icon type="ptzControl"/>
                            </Tooltip>
                        </div> : null
                    }
                    <Modal
                        visible={this.state.visible}
                        getContainer={() => {
                            return document.querySelector('._SmokeDetectRuleConfigPlugin_');
                        }}
                        width={300}
                        footer={null}
                        maskClosable={false}
                        mask={true}
                        onCancel={this.hidePTZControl}
                    >
                        <PtzControl
                            plugin={plugin}
                            showZoom={isShowZoom}
                            showFocus={isShowFocus}
                            showIris={isShowIris}
                            showSavePreset={curPreset === 1000 ? false : true}
                            currentPtzId={curPreset}
                            currentPtzName={curPtzName}
                            currentChannel={curChannel}
                            hideFastPostion={true}
                        />
                    </Modal>
                </div>

            </div>
        );
    }
}

export default LangConsumer(bindLifecycle(ForeignDetectRuleConfigPluginComponent));